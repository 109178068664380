<template>
  <div
    v-loading="loading"
    class="object-parameters__details w-3/4 overflow-auto mb-5"
  >
    <el-form
      :model="model"
      :ref="refs.parametersValidation"
      class="flex flex-col"
    >
      <div
        v-for="(item, idx) in data"
        :key="item.key"
        :class="[
          'flex',
          'justify-between',
          'items-center',
          'h-8',
          'px-20',
          'w-full',
          { 'bg-lightGrey-100 pt-1': idx % 2 === 0 }
        ]"
      >
        <el-tooltip
          :open-delay="700"
          :content="item.name"
          :offset="2"
          :visible-arrow="false"
          effect="dark"
          placement="bottom"
        >
          <span class="max-w-15 truncate">
            {{ item.name }}
          </span>
        </el-tooltip>
        <div class="flex items-center gap-5">
          <el-form-item
            v-if="item.type === 'input'"
            :rules="parameterRule"
            :prop="`value_${idx}`"
          >
            <el-input
              v-model="item.value"
              class="object-parameters__details--input"
              @input="validateInput(item, idx)"
            />
          </el-form-item>
          <el-form-item v-else>
            <el-select
              v-model="item.value"
              :disabled="item.disabled || false"
              class="object-parameters__details--select"
            >
              <el-option
                v-for="option in customField(item.key).values"
                :key="option.key"
                :value="option.key"
                :label="option.value"
              />
            </el-select>
          </el-form-item>
          <el-tooltip
            :open-delay="700"
            :content="$t('new_units.objects.fields.parameters.switch.hint')"
            :visible-arrow="false"
            effect="dark"
            placement="bottom"
          >
            <UnitsSwitch
              :value="item.show_in_monitoring"
              @onChange="item.show_in_monitoring = $event"
            />
          </el-tooltip>
          <el-tooltip
            v-if="item.key === 'video_cameras'"
            :content="$t('new_units.objects.fields.parameters.video.hint')"
            placement="top"
          >
            <NoteInfoGreyIcon class="absolute right-5" />
          </el-tooltip>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue'

import { createVuexHelpers } from 'vue2-helpers'
import { useI18n } from '@/hooks/useI18n'
import { refs, checkPattern } from '@/components/unitsNew/helpers/index.js'

import NoteInfoGreyIcon from '@/components/unitsNew/assets/icons/note-info_grey.vue'
import UnitsSwitch from '@/components/unitsNew/components/UnitsSwitch.vue'
import sections from '@/enums/newUnits/parameters-sections.js'

const { useGetters } = createVuexHelpers()
const $t = useI18n()

const { customField } = useGetters('properties', ['customField'])

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  },
  activeTab: {
    type: String,
    default: ''
  }
})

const invalidFields = ref([])

const model = ref({})

props.data.forEach((field, idx) => {
  model.value[`value_${idx}`] = field.value
})

const validateInput = (field, idx) => {
  const isValid = checkPattern(field.pattern, field.value)

  !isValid
    ? invalidFields.value.push(`value_${idx}`)
    : (invalidFields.value = invalidFields.value.filter(
        (field) => `value_${idx}` !== field
      ))

  refs.parametersValidation.value.validateField(`value_${idx}`)
}

const validateField = (rule, value, callback) => {
  if (!invalidFields.value.includes(rule.field)) {
    callback()
  } else {
    callback(new Error())
  }
}

const parameterRule = [
  {
    validator: validateField,
    message: $t('new_units.objects.fields.parameters.errors.wrong_value')
  }
]
</script>
