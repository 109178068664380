var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"object-parameters__details w-3/4 overflow-auto mb-5"},[_c('el-form',{ref:_setup.refs.parametersValidation,staticClass:"flex flex-col",attrs:{"model":_setup.model}},_vm._l((_vm.data),function(item,idx){return _c('div',{key:item.key,class:[
        'flex',
        'justify-between',
        'items-center',
        'h-8',
        'px-20',
        'w-full',
        { 'bg-lightGrey-100 pt-1': idx % 2 === 0 }
      ]},[_c('el-tooltip',{attrs:{"open-delay":700,"content":item.name,"offset":2,"visible-arrow":false,"effect":"dark","placement":"bottom"}},[_c('span',{staticClass:"max-w-15 truncate"},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('div',{staticClass:"flex items-center gap-5"},[(item.type === 'input')?_c('el-form-item',{attrs:{"rules":_setup.parameterRule,"prop":`value_${idx}`}},[_c('el-input',{staticClass:"object-parameters__details--input",on:{"input":function($event){return _setup.validateInput(item, idx)}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1):_c('el-form-item',[_c('el-select',{staticClass:"object-parameters__details--select",attrs:{"disabled":item.disabled || false},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}},_vm._l((_setup.customField(item.key).values),function(option){return _c('el-option',{key:option.key,attrs:{"value":option.key,"label":option.value}})}),1)],1),_c('el-tooltip',{attrs:{"open-delay":700,"content":_setup.$t('new_units.objects.fields.parameters.switch.hint'),"visible-arrow":false,"effect":"dark","placement":"bottom"}},[_c(_setup.UnitsSwitch,{attrs:{"value":item.show_in_monitoring},on:{"onChange":function($event){item.show_in_monitoring = $event}}})],1),(item.key === 'video_cameras')?_c('el-tooltip',{attrs:{"content":_setup.$t('new_units.objects.fields.parameters.video.hint'),"placement":"top"}},[_c(_setup.NoteInfoGreyIcon,{staticClass:"absolute right-5"})],1):_vm._e()],1)],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }